import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '../../shared/tokens/anatomy.stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';

export const styles = stylex.create({
  title: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeHeading-3'],
    fontWeight: typography.fontWeighDemi,
    lineHeight: typography['fontSizeHeading-1'],
    textAlign: 'center',
    paddingBottom: '36px',
    color: colors.primaryText,
  },
  navigation: {
    display: 'flex',
    gap: '16px',
    paddingTop: '120px',
    paddingBottom: '50px',
  },
  btnText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeBody-3'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightBody-3'],
    textAlign: 'left',
  },
  prevBtn: {
    color: colors.primaryText,
  },
  nextBtn: {
    color: colors.primaryInverseText,
  },
  label: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-2'],
    textAlign: 'left',
    color: colors.secondaryText,
  },
  deleteFile: {
    background: 'none',
    border: 'none',
    margin: '0',
    padding: '0',
    font: 'inherit',
    textDecoration: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: '12px',
    textAlign: 'left',
    color: colors.errorText,
  },
  imgPreview: {
    maxWidth: '320px',
    maxHeight: 'auto',
    borderRadius: anatomy.borderRadiusS,
    border: '1px solid',
    borderColor: colors.ghostBorder,
    overflow: 'hidden',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  dropdown: {
    position: 'relative',
  },
  input: {
    paddingTop: '24px',
  },
  inputDate: {
    ':not(#__unused__) > div > div': {
      width: '320px',
    },
    ':not(#__unused__) > div': {
      width: '320px',
    },
    ':not(#__unused__) > div > div > input': {
      width: '320px',
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '300px',
  },
});

import { Switcher } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC, ReactNode, useEffect, useState } from 'react';

import { styles } from './styles';
import { ModalAddOrganizer } from '@features/modal-organizer';
import { OranizerTab } from '@features/organizer-tab';
import { IOrganizer } from '@features/organizer/model/types';
import { SegmensTab } from '@entities/segments-tab';
import { ITab, Tabs } from '@entities/tabs';

import { IEvent, IEventResponse, ISegment } from '@pages/event/api/useFetchEvent';
import { ModalAddSegment } from '@features/add-segment/ui/modal-add-segment';
import { ModalTickets } from '@features/modal-sms';
import { TABS_LIST } from '../../lib/constants';
import { useConfirm } from '@shared/hooks/useConfirm';
import { SmsResult, useFetchSMS } from '../../api/useFetchSms';
import { useFetchAddSegment } from '@pages/event/api/useFetchAddSegment';
import { useFetchDeleteSegment } from '@pages/event/api/useFetchDeleteSegment';
import { useFetchAddOrganizer } from '@pages/event/api/useFetchAddOrganizer';
import { useGetOrganizers } from '@pages/event/api/useGetOrganizers';
import { useSendSMStoAdmin } from '@pages/event/api/useSendSmsToAdmin';
import { useToast } from '@shared/hooks/useToast';
import SettingsIcon from '@shared/ui/icons/settings-icon';
import { SettingsPopup } from '@features/settingsPopup';
import { useFetchMail } from '@pages/event/api/useFetchMail';
import { useFetchDeleteUser } from '@pages/event/api/useFetchDeleteUser';
import { useUpdateEventMutation } from '@pages/event/api/useUpdateEventMutation';
import { CalendarIcon } from '@shared/ui/icons/calendar-icon';
interface IEventsProps {
  event: IEventResponse | undefined;
}

export const EventBody: FC<IEventsProps> = ({ event }) => {
  const { openConfirmDialog } = useConfirm();

  const [activeTab, setActiveTab] = useState<ITab>(TABS_LIST[0]);
  const [organizers, setOrganizers] = useState<IOrganizer[]>([]);
  const [showModalOranizer, setShowModalOranizer] = useState(false);
  const [showModalAddSegment, setShowModalAddSegment] = useState(false);
  const [isSettingsPopupVisible, setIsSettingsPopupVisible] = useState(false);
  const [smsRecipients, setSmsRecipients] = useState<SmsResult[]>([]);

  const [usersSMS, setUsersSMS] = useState<{ users: number[]; tickets: number[]; isAdmin: boolean }>({
    users: [],
    tickets: [],
    isAdmin: false,
  });

  const [showModalTickets, setShowModalTickets] = useState(false);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [segments, setSegments] = useState<ISegment[]>([]);

  const [{ error }, updateEvent] = useUpdateEventMutation();
  const [currentEvent, setCurrentEvent] = useState<IEvent | undefined>(undefined);
  const [{ error: addOrganizerError }, addOrganizer] = useFetchAddOrganizer();
  const [{ error: addSegmentError, fetching: segmFetching }, addSegment] = useFetchAddSegment();
  const [, deleteSegment] = useFetchDeleteSegment();
  const [, deleteOrganizer] = useFetchDeleteUser();
  const { data: organizersList } = useGetOrganizers(event?.getEventById.id);

  const { showToast } = useToast();

  const toggleSettingsPopup = () => {
    setIsSettingsPopupVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!event) return;
    setCurrentEvent(event.getEventById);
    setSegments(event.getSegmentsByEventId);
  }, [event]);

  useEffect(() => {
    if (!organizersList) return;
    setOrganizers(organizersList.getOrganizersByEventId);
  }, [organizersList]);

  const [{ fetching: smsFetching }, sender] = useFetchSMS();
  const { sendEmail, fetching: emailFetching } = useFetchMail();
  const [{ fetching: smsFetchingAdmin }, senderAdmin] = useSendSMStoAdmin();
  //обновление эвента

  const handleToggleActive = async () => {
    if (!currentEvent) return;

    const newActiveState = !currentEvent.active;

    try {
      const result = await updateEvent({
        id: currentEvent.id,
        active: newActiveState,
        name: currentEvent.name,
        description: currentEvent.description,
        image: currentEvent.image,
        dateStart: currentEvent.dateStart,
        dateEnd: currentEvent.dateEnd,
        confirmed: currentEvent.confirmed,
      });

      if (result.error) {
        showToast('Ошибка при обновлении события', 'error');
      } else {
        setCurrentEvent({ ...currentEvent, active: newActiveState });
        showToast('Настройки успешно сохранены', 'success');
      }
    } catch (error) {
      showToast('Ошибка при обновлении события', 'error');
    }
  };

  if (error) {
    return <div>Произошла ошибка: {error.message}</div>;
  }

  const handleCancelOrganizer = () => {
    setShowModalOranizer(false);
  };

  const handleCancelTickets = () => {
    setShowModalTickets(false);
  };

  // сегменты
  const handleAddSegment = async (title: string) => {
    if (!event) return;
    const res = await addSegment({ title, eventId: event.getEventById.id, description: '' });
    if (addSegmentError || res.data === undefined) {
      showToast('Произошла ошибка при добавлении сегмента', 'error');
      return;
    }
    segments.push(res.data.createTicketSegment);
    setSegments(segments);
    setShowModalAddSegment(false);
  };

  const handleCancelAddSegment = () => {
    setShowModalAddSegment(false);
  };

  // приглдашения СМС
  const handleShowModalTickets = ({
    users,
    tickets = [],
    isAdmin = false,
  }: {
    users: number[];
    tickets?: number[];
    isAdmin?: boolean;
  }) => {
    setUsersSMS({ users, tickets, isAdmin });
    setShowModalTickets(true);
  };

  const sendSMS = async (message: string) => {
    if (!event) return;

    if (
      process.env.NODE_ENV === 'development' ||
      import.meta.env.MODE === 'development' ||
      window.location.origin.includes('dev')
    ) {
      // console.info(`[DEV MODE] SMS message: ${message}`);
      return;
    }
    try {
      if (usersSMS.isAdmin) {
        const smsRes = await senderAdmin({
          message,
          users: usersSMS.users[0] ? usersSMS.users : [],
          event: event.getEventById.id,
        });

        if (smsRes.error) {
          throw new Error('Ошибка при отправке СМС');
        }
      } else {
        const smsRes = await sender({ message, tickets: usersSMS.tickets });

        if (smsRes.error) {
          throw new Error('Ошибка при отправке СМС');
        }

        if (smsRes.data && smsRes.data.sendSms) {
          setSmsRecipients(smsRes.data.sendSms.filter((user) => user.id));
        }
      }
    } catch (error) {
      showToast('Произошла ошибка при отправке СМС', 'error');
    }
  };

  const sendEmails = async (message: string) => {
    if (
      process.env.NODE_ENV === 'development' ||
      import.meta.env.MODE === 'development' ||
      window.location.origin.includes('dev')
    ) {
      // console.info(`[DEV MODE] EMAIL message: ${message}`);
      return;
    }
    try {
      const emailRes = await sendEmail(message, usersSMS.tickets);
      if (emailRes.error) {
        throw new Error('Ошибка при отправке Email');
      }
    } catch (error) {
      showToast('Произошла ошибка при отправке Email', 'error', {
        duration: 10000,
        closable: true,
      });
      throw error;
    }
  };

  /// организаторы
  const handleAddOrganizer = async (newOrganizer: Omit<IOrganizer, 'id'>) => {
    if (!event) return;
    const input = {
      eventId: event.getEventById.id,
      email: newOrganizer.email,
      phone: newOrganizer.phone,
      firstName: newOrganizer.firstName,
      lastName: newOrganizer.lastName,
    };

    const res = await addOrganizer({ input });
    if (addOrganizerError) {
      showToast('Произошла ошибка при добавлении организатора', 'error');
      return;
    }
    setOrganizers([...organizers, { ...newOrganizer, id: res.data?.createEventOrganizer.id }]);
    setShowModalOranizer(false);
  };

  const handleDeleteOrganizer = async (organizer: IOrganizer) => {
    if (!event) return;

    const res = await deleteOrganizer({ id: organizer.id });
    if (res.data !== undefined && res.data.deleteUser.success) {
      setOrganizers(organizers.filter((item) => item.id !== organizer.id));
      return;
    }
    showToast('Произошла ошибка при удалении организатора', 'error');
  };
  const handleChange = (organizer: IOrganizer) => {
    setOrganizers(organizers.map((item) => (item.id === organizer.id ? organizer : item)));
  };

  /**
   * Удаление сегмента
   * @param segment
   */
  const handleDeleteSegment = async (segment: ISegment) => {
    if (await openConfirmDialog(`Удалить сегмент ${segment.title} ?`, 'Удаление сегмента', 'Удалить')) {
      const res = await deleteSegment({ id: segment.id });
      if (res.data !== undefined && res.data.deleteTicketSegment.success) {
        setSegments(segments.filter((item) => item.id !== segment.id));
        return;
      }
      showToast('Произошла ошибка при удалении сегмента', 'error');
    }
  };

  const eventName = typeof currentEvent?.name === 'string' ? currentEvent.name : '';
  const truncatedTitle = eventName.length > 55 ? eventName.slice(0, 55) + '...' : eventName;

  const TABS: ReactNode[] = [
    <SegmensTab
      segments={segments}
      onDeleteSegment={handleDeleteSegment}
      onAddSegment={() => setShowModalAddSegment(true)}
      sendTickets={(users, tickets) => handleShowModalTickets({ users, tickets })}
    />,
    <OranizerTab
      organizers={organizers}
      handleAdd={() => setShowModalOranizer(true)}
      handleChange={(e) => handleChange(e)}
      handleDelete={handleDeleteOrganizer}
      sendTickets={(users) => handleShowModalTickets({ users, isAdmin: true })}
      setSortDirection={setSortDirection}
      setOrganizers={setOrganizers}
      sortDirection={sortDirection}
    />,
  ];

  return (
    <>
      <ModalAddOrganizer
        active={showModalOranizer}
        closeHandle={handleCancelOrganizer}
        addOrganizer={handleAddOrganizer}
      />
      <ModalAddSegment
        active={showModalAddSegment}
        closeHandle={handleCancelAddSegment}
        addSegment={handleAddSegment}
        segmFetching={segmFetching}
      />

      <ModalTickets
        isLoading={smsFetching || smsFetchingAdmin || emailFetching}
        active={showModalTickets}
        closeHandle={handleCancelTickets}
        sendSMS={sendSMS}
        sendEmails={sendEmails}
        smsRecipients={smsRecipients}
        isAdmin={usersSMS.isAdmin}
        usersSMS={usersSMS}
      />

      <div {...stylex.props(styles.base)}>
        <h1 {...stylex.props(styles.header)}>{truncatedTitle}</h1>
        <div {...stylex.props(styles.row)}>
          <div {...stylex.props(styles.row)}>
            <Switcher
              changeFunction={handleToggleActive}
              size="small"
              isActive={currentEvent?.active}
              variant="default"
            />
            <p {...stylex.props(styles.switcherLabel)}>Активность</p>
            <p {...stylex.props(styles.dateLabel)}>
              <span {...stylex.props(styles.date)}>
                {currentEvent?.dateStart.split('T')[0].split('-').reverse().join('.')}
              </span>
              <CalendarIcon />
            </p>
          </div>
          <div {...stylex.props(styles.settingsControl)} onClick={toggleSettingsPopup}>
            <SettingsIcon />
            <p {...stylex.props(styles.settingsLabel)}>Настройка события</p>
          </div>
        </div>
        <Tabs activeTab={activeTab} size="medium" tabs={TABS_LIST} onChange={setActiveTab} />
        <div {...stylex.props(styles.tabs)}>{TABS[activeTab.id]}</div>

        <SettingsPopup isVisible={isSettingsPopupVisible} onClose={() => setIsSettingsPopupVisible(false)} />
      </div>
    </>
  );
};

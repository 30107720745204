import { useMutation, gql } from 'urql';

const UPDATE_SETTING = gql`
  mutation UpdateSetting($id: Int!, $formData: UpdateSetting!) {
    updateSetting(id: $id, formData: $formData) {
      id
      eventId
      showFioInApp
    }
  }
`;

const useUpdateSetting = () => {
  const [result, executeMutation] = useMutation(UPDATE_SETTING);

  const updateSetting = async (id: number, formData: { showFioInApp: boolean }) => {
    const response = await executeMutation({
      id,
      formData,
    });

    return response;
  };

  return {
    updateSetting,
    result,
  };
};

export default useUpdateSetting;

import { Button, PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ExcelIcon from './lib/ui/excel-icon.tsx';
import ButtonWithPlus from '@shared/ui/button-with-plus/index.tsx';
import useUploadFileDnd from '@shared/hooks/useUploadFileDnd.ts';
import { useEffect, useRef, useState } from 'react';
import { ModalSegment } from '@features/modal-segment/index.tsx';
import { useToast } from '@shared/hooks/useToast.ts';
import { uploadFile } from '@pages/event/api/excelUpload.ts';
import { downloadTemplate } from '@pages/event/api/excelTemplate.ts';
import { colors } from '@shared/tokens/colors.stylex.ts';

interface WithExcelProps {
  segmentId: number;
  eventId: number;
}

interface IUserData {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
}
interface ITicketResult {
  id: number;
  status: string;
}

export interface UploadedData {
  errorUsers: number;
  existingUsers: number;
  errorUsersData: IUserData[];
  existingUsersData: IUserData[];
  ticketResults: ITicketResult[];
}

const WithExcel: React.FC<WithExcelProps> = ({ segmentId, eventId }) => {
  const [uploadedData, setUploadedData] = useState<UploadedData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const { showToast } = useToast();

  const { handleDragLeave, handleDragOver, setDragActive } = useUploadFileDnd();
  useEffect(() => {}, [uploadedData]);

  const excelInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    excelInputRef?.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer.files?.length && event.dataTransfer.files[0];

    if (file) {
      const { size } = file;
      const maxSize = 1.5 * 1024 * 1024;
      if (size > maxSize) return;
      if (excelInputRef.current) {
        excelInputRef.current.files = event.dataTransfer.files;
      }

      handleUploadFile(file);
    }
  };
  const resetToInitialState = () => {
    setUploadedData(null);
    setIsLoading(false);
    setHasError(false);
    setErrorFile(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      handleUploadFile(file);
    }
  };

  const handleUploadFile = async (file: File) => {
    setIsLoading(true);
    setHasError(false);
    setErrorFile(false);

    const validExtensions = ['xlsx'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (!validExtensions.includes(fileExtension || '')) {
      setHasError(true);
      showToast('Ошибка при добавлении сотрудников', 'error');
      setIsLoading(false);
      return;
    }

    try {
      const responseData = await uploadFile(file, eventId, segmentId);
      setUploadedData(responseData);
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
      setErrorFile(true);
    }

    setIsLoading(false);
  };

  const handleDownloadTemplate = async () => {
    try {
      await downloadTemplate();
    } catch (error) {
      console.error('Ошибка при скачивании шаблона:', error);
    }
  };

  return (
    <div {...stylex.props(styles.withExcelBase)}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PreloaderCircular />
        </div>
      ) : hasError ? (
        <div style={{ color: colors.errorText, textAlign: 'center' }}>
          Неверный формат файла. Ожидается файл формата .xlsx.
        </div>
      ) : errorFile ? (
        <div style={{ color: colors.errorText, textAlign: 'center' }}>
          Ошибка в заполнении шаблона, проверьте данные.
        </div>
      ) : uploadedData ? (
        <ModalSegment uploadedData={uploadedData} onBack={resetToInitialState} /> // Отображаем ModalSegment после успешной загрузки
      ) : (
        <>
          <div {...stylex.props(styles.steps)}>
            <p {...stylex.props(styles.stepsText)}>Шаг 1 *</p>
            <Button variant="secondary" size="small" settings={{ width: '164px' }} onClick={handleDownloadTemplate}>
              <p {...stylex.props(styles.textBtn)}>Скачать шаблон.xlsx</p>
            </Button>
          </div>
          <div {...stylex.props(styles.steps)}>
            <p {...stylex.props(styles.stepsText)}>Шаг 2 *</p>
            <div
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              {...stylex.props(styles.excelInput())}
            >
              <ExcelIcon />
              <div {...stylex.props(styles.excelText)}>
                Импортируйте файл Excel с предварительно заполненными участниками
              </div>
              <input type="file" ref={excelInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
              <ButtonWithPlus labelText="Импортировать" handleClick={handleClick} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WithExcel;

import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import { palette } from '@shared/tokens/palette.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    minHeight: '200px',
    padding: '0px',
  },

  header: {
    padding: '16px 24px',
    color: colors.primaryText,
    fontSize: '24px',
    fontFamily: fonts.din2014Regular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    padding: ' 16px 24px 16px ',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputWrapper: {
    display: 'flex',
    padding: '0px 24px',
    flexDirection: 'column',
    gap: '16px',
  },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  input: {
    paddingBottom: '14px',
  },
  textCount: {
    position: 'absolute',
    zIndex: -1,
    color: palette['grey-500'],
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    right: '25px',
    top: '141px',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: anatomy['size-4'],
    alignSelf: 'stretch',
  },
  labelPreview: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-2'],
  },
  areaPreview: {
    display: 'flex',
    padding: anatomy['size-16'],
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: anatomy['size-8'],
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: colors.ghostBackground,
  },
  messagePreview: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: palette['grey-700'],
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  linkPreview: {
    color: colors.accentText,
    fontFamily: fonts.din2014Regular,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationSkipInk: 'auto',
    textDecorationThickness: 'auto',
    textUnderlineOffset: 'auto',
    textUnderlinePosition: 'from-font',
  },
  infoQuantity: {
    position: 'absolute',
    left: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  infoText: {
    overflow: 'hidden',
    color: colors.secondaryText,
    textOverflow: 'ellipsis',
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: typography['lineHeightCaption-2'],
  },
  infoCount: {
    overflow: 'hidden',
    color: palette['grey-950'],
    textOverflow: 'ellipsis',
    fontFamily: fonts.din2014Demi,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: typography['lineHeightCaption-2'],
  },
  text: {
    fontSize: typography['fontSizeCaption-1'],
    padding: '16px 24px 6px 24px',
    color: colors.secondaryText,
  },
});
